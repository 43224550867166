<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on: dialog }">
        <v-tooltip transition="fade-transition" top>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              :color="btnProps.color || 'primary'"
              icon
              depressed
              v-on="{ ...dialog, ...tooltip }"
              v-bind="btnProps"
            >
              <v-icon> mdi-email-alert </v-icon>
            </v-btn>
          </template>

          <span>Resend Quote</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-form ref="form" v-model="formValid">
          <v-card-title class="white--text text-h5 blue lighten-2">
            Resend Quote To: {{ patientName }}
          </v-card-title>

          <v-card-text>
            <v-checkbox
              label="Update Patient Email"
              v-model="checkedit"
            ></v-checkbox>
            <v-text-field
              v-model="emaildata"
              label="Email"
              outlined
              :rules="[rules.required, rules.email]"
              dense
              class="mt-2"
            ></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeDialog"> Cancel </v-btn>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="!formValid"
              text
              @click="_resend"
            >
              Send
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import rules from "@/components/account/rules";
import { getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { mapState } from "vuex";
export default {
  props: {
    btnProps: {
      type: Object,
      default: () => ({}),
    },
    quote: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      formValid: false,
      rules,
      dialog: false,
      loading: false,
      checkedit: false,
      showErrors: false,
      errorMessage: "",
      emaildata: "",
      patientName: "",
      quoteId: "",
      patientUuid: "",
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["patientDetail"]),
  },
  watch: {
    dialog(val) {
      if (val) {
        this.emaildata = this.patientDetail.email;
        this.patientName = this.patientDetail.fullname;
        this.patientUuid = this.patientDetail.uuid;
        this.quoteId = this.quote.id;
      }
    },
    quote(val) {
      if (val != null && val != undefined) {
        this.emaildata = this.patientDetail.email;
        this.patientName = this.patientDetail.fullname;
        this.patientUuid = this.patientDetail.uuid;
        this.quoteId = this.quote.id;
      }
    },
  },

  methods: {
    _resend() {
      this.loading = true;
      if (this.checkedit) {
        getAPI
          .put("/patient/updatePatient/" + this.patientUuid, {
            email: this.emaildata,
          })
          .then(() => {
            notifySuccess("Patient Email has been updated!");
          })
          .catch((error) => {
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
      getAPI
        .put(`/sprocedures/resendQuoteByEmailToSign`, {
          quoteId: this.quoteId,
          email: this.emaildata,
        })
        .then(() => {
          this.loading = false;
          notifyInfo(this.$t("notification"));
          this.closeDialog();

          this.$emit("sended");
        })
        .catch((error) => {
          this.closeDialog();
          this.loading = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.showErrors = false;
      this.errorMessage = "";
      this.emaildata = "";
      this.quoteId = "";
      this.checkedit = false;
      this.patientName = "";
      this.patientUuid = "";
    },
  },
};
</script>
<i18n>
{
  "en": {
    "notification": "Quote have been sended"
  },
  "es": {
    "notification": "El Quote han sido enviados"
  }
}
</i18n>
